function mobileOpen() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
    	$('.mobile-header-logo').hide();
        x.className += " responsive";
    } else {
        x.className = "topnav";
        $('.mobile-header-logo').show();
    }
}

$(document).ready(function(){
	$('.mobile-menu-handler').click(function(){
		$('.mobile-menu-collapse').toggle();
	});
	
	var current_url = window.location.pathname;
	var showType = '';
	if(current_url == '/sports'){
		showType = 'sports';
	} else {
		showType = 'news';
	}
	multiselect('div.multiselect');
	showTrending();
	showTrendingType(showType);
	mobileOpen();
	initializeDatePicker();
});

function multiselect(selector) {
	$(selector).each(function (i, o) {
		var available = $(this).find('select.multiselect:eq(0)');
		var selected = $(this).find('select.multiselect:eq(1)');
		function sort(options) {
			var arr = options.map(function(_, o) { return { t : $(o).text(), v : o.value }; }).get();
			arr.sort(function(o1, o2) { return o1.t > o2.t ? 1 : o1.t < o2.t ? -1 : 0; });
			options.each(function(i, o) {
				o.value = arr[i].v;
				$(o).text(arr[i].t);
			});
		}
		function move() {
			if ($(this).parent().hasClass('available')) {
				$(this).appendTo(selected);
			} else {
				$(this).appendTo(available);
			}
			sort($(this).parent().find('option'));
			$(this).parent().find('option').prop('selected', false);
		}
		var availableOptions = $(this).find('select.multiselect:eq(0) option').off().prop('selected', false).click(move);
		var selectedOptions = $(this).find('select.multiselect:eq(1) option').off().prop('selected', false).click(move);
		$.each(selectedOptions, function () {
			available.find('option[value="' + $(this).val() + '"]').remove();
		});
		$('form').submit(function () {
			selected.find('option').prop('selected', false);
			selected.find('option').prop('selected', true);
		});
	});
}

/* Set the width of the side navigation to 250px */
function openNav() {
    document.getElementById("mySidenav").style.width = "350px";
}

/* Set the width of the side navigation to 0 */
function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
}

//funtionality for trending homepage ride block visibility
function showTrending(timeSpan){
	$('.trending-today-select').addClass("link-text-black");
	$('.trending-thirty-select').addClass("text-muted");
	$('.trending-news-select').addClass("link-text-black");
	$('.trending-sports-select').addClass("text-muted");
	if(timeSpan == 'today'){
		$('.trending-thirty-select').removeClass("link-text-black");
		$('.trending-today-select').removeClass("text-muted");
		$('.trending-today-select').addClass("link-text-black");
		$('.trending-thirty-select').addClass("text-muted");
		$('#trending-thirty').hide();
		$('#trending-today').show();
		$('#trending-type-today-news').show();
	}
	if(timeSpan == 'thirty'){
		$('.trending-today-select').removeClass("link-text-black");
		$('.trending-thirty-select').removeClass("text-muted");
		$('.trending-thirty-select').addClass("link-text-black");
		$('.trending-today-select').addClass("text-muted");
		$('#trending-today').hide();
		$('#trending-thirty').show();
		$('#trending-type-thirty-news').show();
		
	}
}
function showTrendingType(type){
	var getId = $('.trending-block-time:visible').attr('id');
	if(type == 'news'){
		$('.trending-sports-select').removeClass("link-text-black");
		$('.trending-news-select').removeClass("text-muted");
		$('.trending-news-select').addClass("link-text-black");
		$('.trending-sports-select').addClass("text-muted");
		if(getId == 'trending-today'){
			$('#trending-type-today-sports').hide();
			$('#trending-type-thirty-sports').hide();
			$('#trending-type-thirty-news').hide();
			$('#trending-type-today-news').show();
		}
		if(getId == 'trending-thirty'){
			$('#trending-type-thirty-sports').hide();
			$('#trending-type-today-sports').hide();
			$('#trending-type-today-news').hide();
			$('#trending-type-thirty-news').show();
		}
	}
	if(type == 'sports'){
		$('.trending-news-select').removeClass("link-text-black");
		$('.trending-sports-select').removeClass("text-muted");
		$('.trending-sports-select').addClass("link-text-black");
		$('.trending-news-select').addClass("text-muted");
		if(getId == 'trending-today'){
			$('#trending-type-today-news').hide();
			$('#trending-type-thirty-news').hide();
			$('#trending-type-thirty-sports').hide();
			$('#trending-type-today-sports').show();
		}
		if(getId == 'trending-thirty'){
			$('#trending-type-today-news').hide();
			$('#trending-type-today-sports').hide();
			$('#trending-type-thirty-news').hide();
			$('#trending-type-thirty-sports').show();
		}
	}
}

/*$(document).ready(function () {
	$('.storyview-rated-tabs li').click(function () {
		$('.storyview-rated-tabs li').removeClass('storyview-rated-tab-selected');
		$(this).addClass('storyview-rated-tab-selected');
		$('.storyview-rated-content').hide();
		$($('.storyview-rated-content').get($('.storyview-rated-tabs li').index($(this)))).show();
	});
	$($('.storyview-rated-tabs li').get(0)).click();

	$('.storyview-rated-viewed-tabs li').click(function () {
		$('.storyview-rated-viewed-tabs li').removeClass('storyview-rated-tab-selected');
		$(this).addClass('storyview-rated-tab-selected');
		$('.storyview-rated-content-viewed').hide();
		$($('.storyview-rated-content-viewed').get($('.storyview-rated-viewed-tabs li').index($(this)))).show();
	});
	$($('.storyview-rated-viewed-tabs li').get(0)).click();
});

$('#block-storyview-1').hide();
var popularValue = $('.mostpopular-select-type').val();
$('.mostpopular-select-type').change(function(){
	if($(this).val() == 'sports'){
		$('#block-storyview-0').hide();
		$('#block-storyview-1').show();
		$('#block-storyview-1 .content .padding-ten .storyview-rated-content .storyview-rated-content-viewed:first').css("display", "block");
		$('#block-storyview-1 .content .padding-ten .storyview-rated-content ul.storyview-rated-viewed-tabs li:nth-child(1)').addClass("storyview-rated-tab-selected");
		if($('#block-storyview-1:visible')){
			$('#block-storyview-1 .content .padding-ten .storyview-rated-content .mostpopular-select-type').val('sports');
		}
	}
	if($(this).val() == 'news'){
		$('#block-storyview-1').hide();
		$('#block-storyview-0').show();
		$('#block-storyview-0 .content .padding-ten .storyview-rated-content .storyview-rated-content-viewed:first').css("display", "block");
		$('#block-storyview-0 .content .padding-ten .storyview-rated-content ul.storyview-rated-viewed-tabs li:nth-child(1)').addClass("storyview-rated-tab-selected");
		if($('#block-storyview-0:visible')){
			$('#block-storyview-0 .content .padding-ten .storyview-rated-content .mostpopular-select-type').val('news');
		}
	}
});*/


/*function getHeadlinesByPaper(value){
	var str = value;
	var paperId = $(str).attr('id');
	$('.headlines-block-container:visible').hide();
	$('#' + paperId).show();
}*/

/*function topheadlinesSelect(element){
	console.log(element);
	$(element).click(function(){
		$('#top-headlines-publication-select').show();
	});
}*/

/* When the user clicks on the button, 
toggle between hiding and showing the dropdown content */
function selectPublication() {
    document.getElementById("myDropdown").classList.toggle("show");
}
function selectPaperPublication() {
	document.getElementById("paperDropdown").classList.toggle("show");
}
function selectSidePublication() {
	document.getElementById("sideDropdown").classList.toggle("show");
}
function selectNewsCategory() {
	document.getElementById("newsDropdown").classList.toggle("show");
}
function selectSportsCategory() {
	document.getElementById("sportsDropdown").classList.toggle("show");
}
function selectFeaturesCategory() {
	document.getElementById("featuresDropdown").classList.toggle("show");
}

// Close the dropdown menu if the user clicks outside of it
window.onclick = function(event) {
  if (!event.target.matches('.dropbtn')) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}
function checkInputType(s) {
	var i = document.createElement('input');
	try {
		i.setAttribute("type", s);
	} catch (e) {
		return false;
	}
	if (i.type == "text") {
		return false;
	}
	return true;
}
function initializeDatePicker(){
	if (!checkInputType("date")) {
		$('input[type="date"]').each(function () {
			if ($(this).val()) {
				$(this).val(Date.parse($(this).val()).toString('M/d/yyyy'));
			}
		});
		$('input[type="date"]').datetimepicker({format: 'M/D/Y'}).on('show', function () {
		});
		$('input[type="time"]').datetimepicker({format: 'LT'}).on('show', function () {
		});
	}
}
$(function (){
	$('#eventdatepicker').datetimepicker({
		format: 'YYYY/MM/DD',
		keepOpen: false,
	});

	// changes url to chosen date
	$('#eventdatepicker').on("dp.hide", function(e){
		$chosenDate = $(this).val();
		window.location = '/events/' + $chosenDate;
	});
});
function getModal(url, options, callBack) {
	$('#modal-window, .modal-backdrop, #modal-loader').remove();
	options = options ? options : {};
	//options.modalOverflow = true;
	options.show = options.show ? options.show : true;
	$.get(url, function (response) {
		var modal = $(response).modal('show');
		if (callBack) callBack(modal, response);
		$('#modal-window').modal(options).on('show', function () {
			if ($('#modal-window').css('position') === 'absolute') {
				returnPosition = true;
				$(window).scrollTop($('#modal-window').offset().top);
			}
		}).on('hidden', function () {
			if (returnPosition) {
				$(window).scrollTop(scrollPosition);
			}
		}).on('hidden.bs.modal', function () {
			$('#modal-window, .modal-backdrop').remove();
		});
	});
}
/*$(function () {
	$('.datepicker').each(function(i,o){
		$(o).datetimepicker({
    		format: 'YYYY-MM-DD'
    	});
	});
	$('.timepicker').each(function(i,o){
        $(o).datetimepicker({
    		format: 'LT'
        });
	});
 });*/

/*if(checkInputType("date")) {
   AppDate = Date.parse(AppDateTime).toString('yyyy-MM-dd');
} else {
   AppDate = Date.parse(AppDateTime).toString('M/d/yyyy');
}*/


